import React from 'react';
import Helmet from 'react-helmet';
import {Link} from 'gatsby';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Banner from 'components/Banner';

import index_banner from 'assets/images/pd_pattern_grey.png';

const WelcomePage = () => {
  return (
    <Layout pageName="welcome">
      <Helmet>
        <title>Moin!</title>
      </Helmet>
      {/* <Banner source={index_banner}></Banner> */}
      <Container>
        <h1>Willkommen im pd_dev:</h1>
        <h3>Schön, dass du dabei bist! In unserem <Link to="/archive/" >Archiv</Link> findest du unsere bisherigen Newsletter.</h3>
      </Container>
    </Layout>
  );
};

export default WelcomePage;
